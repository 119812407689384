<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <div style="max-height: 350px; overflow: auto; margin-bottom: 15px; padding: 5px;">
            <div
              v-for="(item, index) in paymentMethods"
              :key="index"
              @click="radioGroup = item.sPaymentMethodId,selectPaymentMethod(item)"
              class="content-card display-flex align-items-center"
              :style="(radioGroup == item.sPaymentMethodId) ? { border: '2px solid #FFA77E' } : { border: '2px solid #FFFFFF' }"
            >
              <div>
                <v-radio-group v-model="radioGroup">
                  <v-radio
                    color="#FFA77E"
                    :value="item.sPaymentMethodId"
                    @change="selectPaymentMethod(item)"
                  ></v-radio>
                </v-radio-group>
              </div>
              <div class="ml-2">
                <p class="text-title mon-medium">{{ item.sName }}</p>
                <p class="text-location mon-regular">{{ item.sMessage }}</p>
              </div>

            </div>
          </div>
          <div class="display-flex align-items-center mt-9">
            <v-icon
              color="#0089FF"
              size="20px"
            >
              mdi-chevron-left
            </v-icon>
            <p
              class="text-return-cart mon-regular"
              @click="nextStep(1)"
            >
              {{ texts.paymentsprocess.buttonReturn }}
            </p>
            <v-spacer></v-spacer>
            <v-btn
              elevation="0"
              :disabled="!validateForm"
              class="button-next mon-bold"
              @click="nextStep(3)"
            >
              {{ texts.paymentsprocess.buttonContinue }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "PaymentProcessWarehouseLayout",
  props: {
    texts: {
      type: Object,
      default: {},
    },
    step: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      paymentMethods: [],
      radioGroup: null,
    };
  },
  beforeMount() {
    this.getPaymentMethod();
  },
  methods: {
    nextStep: function (e) {
      this.$emit("sendParam", e);
    },
    selectPaymentMethod: function (e) {
      this.$emit("sendParamPaymentMethod", e);
      if (e.sPaymentMethodId === 'ae42748d-c76c-4a06-9b63-5246b6dc62cd') {
        this.$router.push('/exchange');
      }
    },
    getPaymentMethod: function () {
      //post credentials and get access token from laravel backend
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.get(`${URI}/api/v1/${this.selectLanguage}/payments-methods`, config)
        .then((response) => {
          this.paymentMethods = response.data.results;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    sFullName: function (e) {
      var matches = e.match(/\b(\w)/g);
      var text = matches.join(""); // JSON
      return text;
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
    validateForm: function () {
      return this.radioGroup !== null;
    },
  },
  watch: {},
};
</script>

<style scoped>
.button-next {
  height: 40px !important;
  background: transparent linear-gradient(264deg, #ffc431 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d !important;
  opacity: 1;
  text-transform: initial;
}

.button-next:disabled {
  background: gray !important;
  cursor: not-allowed;
  pointer-events: visible;
}

.text-return-cart {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0089ff;
  opacity: 1;
  margin-bottom: 0px;
}

.text-return-cart:hover {
  text-decoration: underline;
  cursor: pointer;
}

.content-radio-button {
  position: absolute;
  right: 10px;
}

.text-location-address {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.text-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-location {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.3;
  margin-bottom: 0px;
}

.content-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 15px;
  position: relative;
}

.content-logo {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8bbbdf 0% 0% no-repeat padding-box;
  border-radius: 19px;
  text-align: center;
  font-size: 35px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}
</style>